import { Helmet } from 'react-helmet'

export type SEOProps = {
  customTitleSuffix?: string
  customDescription?: string
  customKeywords?: string[]
}

const SEO: React.FC<SEOProps> = ({ customTitleSuffix, customDescription, customKeywords = [] }) => {
  const defaultTitlePrefix = 'Event Paradies - '
  const defaultTitleSuffix = 'Veranstaltungsvermietung'
  const defaultDescription =
    'Wir statten Ihre Feier und Veranstaltung aus. Ob Hochzeit, Geburtstag, Firmenfeier oder Gartenparty. Bei uns mieten Sie alles aus einer Hand.'
  const defaultKeywordsPrimary = [
    'Berlin',
    'Teltow-Fläming',
    'Blankenfelde',
    'Mahlow',
    'Zossen',
    'Am Mellensee',
    'Veranstaltungsvermietung',
    'Eventvermietung',
  ]
  const defaultKeywordsSecondary = ['Hochzeit', 'Geburtstag', 'Firmenfeier', 'Gartenparty']

  const getKeywords = () => {
    return Array.from(new Set([...defaultKeywordsPrimary, ...customKeywords, ...defaultKeywordsSecondary]))
  }

  return (
    <Helmet>
      {customTitleSuffix ? <title>{defaultTitlePrefix + customTitleSuffix}</title> : <title>{defaultTitlePrefix + defaultTitleSuffix}</title>}
      {customDescription ? <meta name="description" content={customDescription} /> : <meta name="description" content={defaultDescription} />}
      <meta name="keywords" content={getKeywords().join(', ')} />
    </Helmet>
  )
}

export default SEO
