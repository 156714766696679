import { s3PublicStoragePrefix } from '../../utility'
import logo from '../../assets/image/logo/white.png'

type Props = {
  image?: string
  imageKey?: string
  video?: string
  videoKey?: string
  text?: string
  subline?: string
  showLogo?: boolean
}

const Hero: React.FC<Props> = ({ image, imageKey, video, videoKey, text, subline, showLogo = false }) => {
  const getSrc = () => {
    if (image) {
      return image
    } else if (imageKey) {
      return `${s3PublicStoragePrefix}${imageKey}`
    } else if (video) {
      return video
    } else if (videoKey) {
      return `${s3PublicStoragePrefix}${videoKey}`
    }
  }

  return (
    <div className={`relative h-[70vh] min-h-[500px] shadow-md`}>
      <div className={`absolute backdrop-blur-sm bg-black bg-opacity-10 h-full top-0 left-0 w-full flex justify-center items-center flex-col`}>
        {!!showLogo && <img src={logo} alt={''} className={`transition-all h-[30vh] md:h-[30vh] md:min-h-[200px] mt-[10vh] md:mt-[5vh]`} />}
        {!!text && (
          <h1 className="font-bold drop-shadow-md tracking-wider text-center text-white text-4xl md:text-5xl xl:text-xxl uppercase mt-[10vh] md:mt-[10vh]">
            {text}
          </h1>
        )}
        {!!subline && <h2 className="font-bold text-white drop-shadow-lg text-lg text-center md:text-3xl mt-[3vh] max-w-[80%]">{subline}</h2>}
      </div>
      {(!!video || !!videoKey) && (
        <video autoPlay muted loop className="absolute -mt-1 min-h-full min-w-full top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <source src={getSrc()} type="video/mp4" />
        </video>
      )}
      {(!!image || !!imageKey) && <img src={getSrc()} alt="hero" className="object-cover h-full w-full" />}
    </div>
  )
}

export default Hero
