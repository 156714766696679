import Header from './Header/Header'
import Footer from './Footer/Footer'
import Secure from './Secure/Secure'
import ScrollToTop from './ScrollToTop/ScrollToTop'
import { AuthLevelRequired } from '../../../types/Auth'
import SEO, { SEOProps } from './SEO/SEO'
type Props = {
  children: React.ReactNode
  className?: string
  authLevelRequired?: AuthLevelRequired
  hideNavigation?: boolean
  noFooter?: boolean
  disableDynamicHeightHeader?: boolean
  smallHeader?: boolean
  seoProps?: SEOProps
}

const Layout: React.FC<Props> = ({
  children,
  className,
  hideNavigation,
  authLevelRequired = [],
  noFooter = false,
  smallHeader = false,
  seoProps,
}) => {
  return (
    <div className={`bg-background ${className}`}>
      <SEO {...seoProps} />
      <Secure authLevelRequired={authLevelRequired}>
        <ScrollToTop />
        <Header hideNavigation={hideNavigation} small={smallHeader} />
        <div className={`relative overflow-x-hidden min-h-screen ${smallHeader && 'pt-16'}`}>{children}</div>
        {!noFooter && <Footer />}
      </Secure>
    </div>
  )
}

export default Layout
