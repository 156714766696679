import Layout from '../../UI/Layout/Layout'
import Hero from '../../Hero/Hero'
import useLoadBundles from '../../hooks/useLoadBundles'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import LargeContainer from '../../UI/Container/LargeContainer'
import PalmBackground from '../../UI/PalmBackground/PalmBackground'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Bundle } from '../../../types/Bundle'
import ProductList from '../../Cart/ProductList'
import Button from '../../UI/Button/Button'
import useCart from '../../Cart/useCart'

const BundleView = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [bundles] = useLoadBundles()
  const [bundle, setBundle] = useState<Bundle>(null)
  const { bundleUUID } = useParams()
  const { addBundle } = useCart()

  useEffect(() => {
    if (bundles && !bundle) {
      setBundle(bundles.find((b) => b.uuid === bundleUUID))
    }
  }, [bundles, bundle, bundleUUID])

  const addAll = () => addBundle(bundle.productUUIDList)

  if (!bundle) return <></>
  return (
    <Layout authLevelRequired={false} seoProps={{ customTitleSuffix: bundle.name, customKeywords: [bundle.name] }}>
      <Hero imageKey={bundle.imageKey} text={bundle.name} />
      <div className="pt-10 pb-32 relative z-20">
        <LargeContainer>
          <div className="my-10 whitespace-pre-wrap">{bundle.description}</div>
          <div className="my-10 font-bold text-md">{t.bundle.includedProducts}</div>
          <ProductList isBundleMode productList={bundle.productUUIDList.map((uuid) => ({ uuid }))} />
          <div className="flex items-center flex-col mt-10 gap-5">
            <div className="font-bold md:w-1/2 text-center">{t.bundle.addAllHint}</div>
            <Button className="text-lg" contrast text={t.bundle.addToCart} onClick={addAll} />
          </div>
        </LargeContainer>
      </div>
      <PalmBackground />
    </Layout>
  )
}

export default BundleView
