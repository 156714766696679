import { useParams } from 'react-router-dom'
import Layout from '../UI/Layout/Layout'
import { useEffect, useState } from 'react'
import Carousel from '../Carousel/Carousel'
import { PricingType, Product } from '../../types/Product'
import Image from '../UI/Image/Image'
import useLoadProducts from '../hooks/useLoadProducts'
// import Hero from '../Hero/Hero'
import useLoadCategories from '../hooks/useLoadCategories'
import { getProductLink } from '../../utility'
import Button from '../UI/Button/Button'
import { useSelector } from 'react-redux'
import props from '../../redux/props'
import Suggestions from './Suggestions/Suggestions'
import OrderOverview from './OrderOverview/OrderOverview'
import Add from '../Cart/AddToCart'
import LargeContainer from '../UI/Container/LargeContainer'
import Price from './Price'
import useCart from '../Cart/useCart'
import { ChevronLeft } from 'lucide-react'

const ProductDetail = () => {
  const [products] = useLoadProducts()
  const [categories] = useLoadCategories()
  const { productUUID } = useParams()
  const [product, setProduct] = useState<Product>(null)
  const [category, setCategory] = useState(null)
  const t = useSelector((s) => s[props.TRANSLATION])
  const [packageUUID, setPackageUUID] = useState(null)
  const { cart } = useCart()

  useEffect(() => {
    if (!packageUUID && !!cart) {
      const cartProduct = cart.productList.find((p) => p.uuid === productUUID)
      if (cartProduct && cartProduct.packageUUID) {
        setPackageUUID(cartProduct.packageUUID)
      }
    }
  }, [cart, productUUID, packageUUID])

  useEffect(() => {
    if (productUUID && Array.isArray(products) && Array.isArray(categories)) {
      const product = products.find((p) => p.uuid === productUUID)

      if (product) {
        setProduct(product)
        const category = categories.find((c) => c.uuid === product.categoryUUID)
        setCategory(category)
      } else {
        window.location.href = getProductLink(product)
      }
    }
  }, [productUUID, products, categories])

  const getImages = () => {
    return product.imageKeyList.map((image, i) => (
      <div key={image} className="w-full h-full pb-10">
        <Image className="w-full aspect-square object-cover rounded-lg" src={image} />
      </div>
    ))
  }

  if (!product || !category) return <></>
  return (
    <Layout authLevelRequired={false} seoProps={{ customTitleSuffix: product.name, customKeywords: [product.name, category.name] }}>
      {/* <Hero imageKey={category.imageKey} small /> */}
      <LargeContainer className="mt-20 md:mt-[7.5rem]">
        <a href={`/kategorie/${category.uuid}`} className="text-md pl-2 flex items-center">
          <ChevronLeft className="stroke-black w-5" />
          <span className="hover:underline text-sm">{t.product.back}</span>
        </a>
      </LargeContainer>
      <LargeContainer className="pb-24 mt-3 md:mt-5">
        <div className="flex flex-col md:flex-row items-start gap-14">
          <div className="w-full md:w-1/2">
            <Carousel key={'detail'} items={getImages()} itemsToShow={1} />
          </div>
          <div className="w-full md:w-1/2">
            <h1 className="font-bold text-2xl md:text-3xl text-black">{product.name}</h1>
            {!!product.subline && <h2 className="text-lg font-regular">{product.subline}</h2>}
            <div className="mt-5 whitespace-pre-wrap">{product.description}</div>
            <Price product={product} packageUUID={packageUUID} setPackageUUID={setPackageUUID} />
            <Add product={product} packageUUID={packageUUID} packageRequired={product.price.pricingType === PricingType.PACKAGES} />
            {(!!product.technicalSheetKey || !!product.operatingInstructionsKey) && (
              <div className="flex flex-col md:flex-row gap-2 md:gap-5 mt-5">
                {!!product.technicalSheetKey && (
                  <Button className="w-full md:w-fit" newTab href={product.technicalSheetURL} text={t.product.detail.technicalSheetDownload} />
                )}
                {!!product.operatingInstructionsKey && (
                  <Button
                    className="w-full md:w-fit"
                    newTab
                    href={product.operatingInstructionsURL}
                    text={t.product.detail.operatingInstructionsDownload}
                  />
                )}
              </div>
            )}
            <div className="mt-5 font-bold">{t.product.deliveryHint.title}</div>
            <div>{t.product.deliveryHint.text}</div>
          </div>
        </div>
        <Suggestions productUUID={productUUID} />
        <OrderOverview />
      </LargeContainer>
    </Layout>
  )
}

export default ProductDetail
