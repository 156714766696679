import Layout from '../UI/Layout/Layout'
import Hero from '../Hero/Hero'
import useLoadBundles from '../hooks/useLoadBundles'
import heroImage from '../../assets/image/bundles/hero.jpg'
import { useSelector } from 'react-redux'
import props from '../../redux/props'
import LargeContainer from '../UI/Container/LargeContainer'
import Bundle from './Bundle'
import PalmBackground from '../UI/PalmBackground/PalmBackground'

const Bundles = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [bundles] = useLoadBundles()

  if (!bundles) return <></>
  return (
    <Layout authLevelRequired={false} seoProps={{ customTitleSuffix: t.bundle.title, customKeywords: bundles.map((b) => b.name) }}>
      <Hero image={heroImage} text={t.bundle.title} />
      <div className="pt-10 pb-32 relative">
        <LargeContainer>
          <div className="my-10 relative z-20">
            <div className="flex flex-col md:flex-row flex-wrap gap-[20px] mt-5">
              {bundles.map((bundle) => (
                <Bundle data={bundle} key={bundle.uuid} />
              ))}
            </div>
          </div>
        </LargeContainer>
        <PalmBackground />
      </div>
    </Layout>
  )
}

export default Bundles
